require('./cookie-consent');
require('./bootstrap');


window.addEventListener('DOMContentLoaded', () => {

    const btnOpenCookieConsentPanel = document.getElementById("openCookieConsentPanel");
    if (btnOpenCookieConsentPanel) {
        btnOpenCookieConsentPanel.addEventListener("click", function () {
            tarteaucitron.userInterface.openPanel();
        });
    }

    const hiddenElementsForCLS = document.getElementsByClassName("cls-fix");
    for (let hiddenElement of hiddenElementsForCLS){
        hiddenElement.classList.remove("d-none");
    }

    const isElementXPercentInViewport = function(el, percentVisible) {
        let
            rect = el.getBoundingClientRect(),
            windowHeight = (window.innerHeight || document.documentElement.clientHeight);

        return !(
            Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
            Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
        )
    };


    window.addEventListener('scroll',function(e) {



        const contactFormElement = document.getElementById("contact");
        const footerCTA = document.getElementById("mobile-footer-cta");

        if(footerCTA && contactFormElement) {
            let showTillHeight = 1000;
            if(contactFormElement){
                showTillHeight = window.scrollY + contactFormElement.getBoundingClientRect().top
            }
            showTillHeight = showTillHeight - 500;

            if(document.documentElement.scrollTop > 80 && document.documentElement.scrollTop < showTillHeight){
                footerCTA.classList.add("mobile-footer-cta-show");
            } else {
                footerCTA.classList.remove("mobile-footer-cta-show");
            }
        }
    });


});



